.main {
    height: 90vh;
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 1140px;
    margin-top: 8rem;
    margin-right: auto;
    margin-left: auto;
}

.card-box-empty {
    width: 100%;
    min-height: 120px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7);
    z-index: 10;
    background-color: white;
}

.card-box-empty > .card-box-head {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-box-empty > .card-box-head > span {
    font-size: 14px;
    color: #6cb2eb !important;
    font-weight: bolder;
}

.card-box-empty > .card-box-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-box {
    width: 100%;
    min-height: 280px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7);
    z-index: 10;
    background-color: white;
}

.card-box > .validation-msg {
    text-align: center;
    margin: 16px;
    color: #38c172 !important;
    font-size: 36px;
}

.card-box > .driver-license {
    max-width: 240px;
    margin: 0 auto;
}

.card-box > .card-box-head {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-box > .card-box-head > span {
    font-size: 14px;
    color: #6cb2eb !important;
    font-weight: bolder;
}

.card-box > .card-box-body {
    width: 100%;
    height: calc(100% - 80px);
    position: relative;
    
}

.card-box > .card-box-body > .card-box-image {
    
    max-width: 100px;
    height: auto;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    padding: 8px;
    aspect-ratio: 5/6;
    
}

.card-box > .card-box-body > .card-box-license {

    position: absolute;
    top: 8px;
    left: 103px;
    display: flex;
    flex-direction: column;
    gap: 8px;

}

.card-box > .card-box-body > .card-box-license > .license-group {
    display: flex;
    gap: 8px;
}

.card-box > .card-box-body > .card-box-license > .license-group > label{
    font-size: 12px;
    font-weight: bold;
    width: 80px;
    text-align: right;
}

.card-box > .card-box-body > .card-box-license > .license-group > span{
    font-size: 12px;
}

.card-box > .card-box-footer{

    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(240, 240, 240);

}

.card-box > .card-box-footer > span {
    font-size: 14px;
}