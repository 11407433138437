.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 70px;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-right: auto;
    margin-left: auto;
}

.navbar > img{
    overflow-clip-margin: content-box;
    overflow: clip;

    vertical-align: middle;
    border-style: none;
}