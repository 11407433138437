body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);
}

#root {
    height: 100vh;
}

* {
    font-family: "Nunito", sans-serif !important;
}